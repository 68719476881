import React from 'react';
import { ApolloProvider } from '@apollo/client';

import client from '@/webapi/client';

import Layout from '../../components/layout/layout';
import Seo from '../../components/layout/seo';
import Form from '../../components/checkout/entry/form';
import { WithCheckout } from '../../hooks/useCheckoutState';

const Checkout = () => {
  return (
    <ApolloProvider client={client}>
      <WithCheckout>
        <Layout>
          <Seo title="Dance" />
          <Form />
        </Layout>
      </WithCheckout>
    </ApolloProvider>
  );
};

export default Checkout;
